/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { User, UserWithoutToken } from '../models/user.model';
import { environment } from 'src/environments/environment';
import { PersistedValueEnum } from '../models/persisted-value-enum';
import { Module } from '../models/module.model';
import { Role } from '../models/role.model';
import { Client } from '../models/client.model';
import { Airdirective } from '../models/airdirective.model';
import { Document } from '../models/document.model';
import { DocTypeCategory } from '../models/docTypeCategory.model';
import { DocType } from '../models/docType.model';
import { Aircraft } from '../models/aircraft.model';
import { AircraftSummary } from '../models/aircraftSummary.model';
import { Engine } from '../models/engine.model';
import { LandingGear } from '../models/landingGear.model';
import { APU } from '../models/apu.model';
import { AssetDocument } from '../models/assetdocument.model';
import { Avionic } from '../models/avionic.model';
import { EngineSummary } from '../models/engineSummary.model';
import { ApuSummary } from '../models/apuSummary.model';
import { LandingGearSummary } from '../models/landingGearSummary.model';
import { GalleyProv } from '../models/galleyProv.model';
import { Propeller } from '../models/propeller.model';
import { PropellerSummary } from '../models/propellerSummary.model';
import { AirAdStatusList } from '../models/airAdStatusList.model';
import { StatusListDocs } from '../models/statusListDocs.model';
import { catchError, switchMap } from 'rxjs/operators';
import { Permissions } from 'src/app/models/permissions.model';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public logPageFormStep = new BehaviorSubject(1);
  public userFullName = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.userFullName) || ''
  );

  public userName = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.userName) || ''
  );

  public userId = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.userId) || ''
  );

  public subDomain = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.subDomain) || ''
  );

  public user = new BehaviorSubject<User | undefined>(localStorage.getItem(PersistedValueEnum.client)
    ? JSON.parse(localStorage.getItem(PersistedValueEnum.client))
    : undefined);

  public client = new BehaviorSubject<Client | undefined>(undefined);

  constructor(
    public http: HttpClient,
    private location: Location,
    private snackBar: MatSnackBar
  ) {
    // values to keep when user refreshes the page
    const storedUser = localStorage.getItem(PersistedValueEnum.user);
    try {
      if (storedUser !== undefined) {
        this.user.next(JSON.parse(storedUser));
      }
    } catch {
      //console.error(`failed to parse user details - ${storedUser}`);
    }

    const storedClient = localStorage.getItem(PersistedValueEnum.client);
    try {
      if (storedClient !== undefined) {
        this.client.next(JSON.parse(storedClient));
      }
    } catch {
      console.error(`failed to parse client details - ${storedClient}`);
    }

    this.userFullName.subscribe(value => {
      localStorage.setItem(PersistedValueEnum.userFullName, value);
    });

    this.userName.subscribe(value => {
      localStorage.setItem(PersistedValueEnum.userName, value);
    });

    this.userId.subscribe(value => {
      localStorage.setItem(PersistedValueEnum.userId, value);
    });

    this.subDomain.subscribe(value => {
      localStorage.setItem(PersistedValueEnum.subDomain, value);
    });

    this.user.subscribe(value => {
      localStorage.setItem(PersistedValueEnum.user, JSON.stringify(value));
    });

    this.client.subscribe(value => {
      localStorage.setItem(PersistedValueEnum.client, JSON.stringify(value));
    });
  }


  // Refresh Token API --------------------------------------------------------------------------------


  //Module API's ---------------------------------------------------------------------------------------

  getModules(data): Observable<Module[]> {
    let params = new HttpParams();
    params = params.append('roleId', data.roleId);
    params = params.append('clientID', data.clientID);
    params = params.append('page', data.page);

    return this.http.get(environment.apiBaseUrl + 'module/list', {
      params: params
    }).pipe(catchError(err => {
      // if (err.status === 401) {
      //   // Handle 401 Unauthorized error
      //   localStorage.removeItem('user');
      localStorage.setItem('userFullName', "");
      localStorage.setItem('userName', "");
      localStorage.setItem('0', "");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("last_activity");
      //   sessionStorage.clear();
      //   window.location.href = '/#/auth';
      //   window.location.reload();
      // }
      return throwError(err)
    })) as Observable<Module[]>;
  }

  //Airworthiness DataBase API's
  getAirWorthinessDirectivesByAssets(assetId,assetTypeId): Observable<Airdirective[]> {
    let params = new HttpParams();
    params = params.append('assetId', assetId);
    params = params.append('assetTypeId', assetTypeId);
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'airworthiness-directive/listByAsset', {
      params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Airdirective[]>;
  }
  getAirDirectives(): Observable<Airdirective[]> {


    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'airworthiness-directive/list', {
       headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Airdirective[]>;
  }

  addAirDirective(data): Observable<Airdirective | string> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'awdb/insert', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Airdirective | string>;
  }

  updateAirDirective(data): Observable<Airdirective | string> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'awdb/update', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Airdirective | string>;
  }

  deleteAirDirective(data): Observable<Airdirective | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'awdb/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Airdirective | string>;
  }

  getAirDirectivesDoc(data): Observable<Document[]> {
    let params = new HttpParams();
    params = params.append('parentId', data.parentId);
    params = params.append('id', data.id);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'awdb/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Document[]>;
  }

  addAirDirectiveStatusDoc(data): Observable<Document | string> {
    let params = new HttpParams();
    // params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'maintenance-docs/insert',data, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Document | string>;
  }
  addAirDirectiveDoc(data): Observable<Document | string> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'awdb/insertdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Document | string>;
  }



  addAirDirectiveDocAsync(data: any, file: File): Observable<Document | string> {

    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'awdb/insertuploaddoc'
    const formData: FormData = new FormData();

    let client = JSON.parse(localStorage.getItem("client"))?.clientID
    formData.append('clientId', client);
    formData.append('file', file);

    formData.append('recordObj', JSON.stringify({ "recordObj": data }));

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('POST', endPoint, formData, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<Document | string>;
  }


  deleteAirDirectiveDoc(data): Observable<Document | string> {
    let payload: any = {
      "recordId": data.recordId,
      "parentId": data.parentId
    }

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'awdb/deletedoc', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Document | string>;
  }

  //Aicraft statusList API's ------------------------------------------------------------------------------------
  //AD ----------------------------------------------------------------------------------------------------------
  getAirMaintenanceDocsList(): Observable<any[]> {
    let params = new HttpParams();
    // params = params.append('recordID', data.recordID);
    // params = params.append('parentID', data.parentID);
    // params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'maintenance-docs/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any[]>;
  }
  getAirAD(data): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('recordID', data.recordID);
    params = params.append('parentID', data.parentID);
    params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraftstatuslist/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any[]>;
  }

  addAirAD(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data.recordObj));
    params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraftstatuslist/insert', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any>;
  }

  updateAirAD(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data.recordObj));
    params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraftstatuslist/update', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any>;
  }

  deleteAirAD(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('parentID', data.parentID);
    params = params.append('recordID', data.recordID);
    params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraftstatuslist/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any>;
  }

  getAirWorthinessDirectiveStatusDoc(data): Observable<StatusListDocs[]> {
    let params = new HttpParams();
    params = params.append('assetMaintenanceMappingId', data.assetMaintenanceMappingId);
    // params = params.append('recordID', data.recordID);
    // params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'maintenance-docs/listByAssetMaintenance', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<StatusListDocs[]>;
  }
  getAirADDoc(data): Observable<StatusListDocs[]> {
    let params = new HttpParams();
    params = params.append('parentID', data.parentID);
    params = params.append('recordID', data.recordID);
    params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraftstatuslist/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<StatusListDocs[]>;
  }

  addAirADDoc(data): Observable<StatusListDocs | string> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data.obj));
    params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraftstatuslist/insertdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<StatusListDocs | string>;
  }

  deleteAirWorthinessDirectiveStatusDoc(data): Observable<StatusListDocs | string> {
    let params = new HttpParams();
    params = params.append('wrecordId', data.recordID);
    // params = params.append('parentID', data.parentID);
    // params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'maintenance-docs/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<StatusListDocs | string>;
  }

  deleteAirADDoc(data): Observable<StatusListDocs | string> {
    let params = new HttpParams();
    params = params.append('recordID', data.recordID);
    params = params.append('parentID', data.parentID);
    params = params.append('statusListID', data.statusListID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraftstatuslist/deletedoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<StatusListDocs | string>;
  }
  //Role API's --------------------------------------------------------------------------------------------------

  getRoles(data?: any): Observable<Role[]> {
    let params = new HttpParams();
    if (data) {
      params = params.append('wroles', data.wroles);
    }
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'role/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Role[]>;
  }

  //Permissions API's --------------------------------------------------------------------------------------------------

  getPermissions(): Observable<Permissions[]> {
    // let params = new HttpParams();
    // params = params.append('wroles', data.wroles);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'permission/listTable', {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Permissions[]>;
  }

  //Get Role by Id API's --------------------------------------------------------------------------------------------------

  getRoleById(id: number): Observable<Permissions[]> {
    let params = new HttpParams();
    params = params.append('id', id);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'role/listRoleById', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Permissions[]>;
  }

  //Client API's ------------------------------------------------------------------------------------------------
  public getClient(data) {
    let params = new HttpParams();
    params = params.append('wclients', data);
    return this.http.get(environment.apiBaseUrl + 'client/list', {
      params: params
    });
  }

  addClient(data): Observable<Client | string> {
    let params = new HttpParams();
    params = params.append('clientObj', JSON.stringify(data.client));
    //params = params.append('user', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'client/insert', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Client | string>;
  }

  updateClient(data): Observable<Client | string> {
    let params = new HttpParams();
    params = params.append('clientObj', JSON.stringify(data.client));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'client/update', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Client | string>;
  }

  //User API's ----------------------------------------------------------------------------------------------------
  getUser(data): Observable<User[]> {
    let params = new HttpParams();
    params = params.append('userId', data.userId);
    params = params.append('clientID', data.clientID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'user/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<User[]>;
  }

  addUser(data): Observable<User | string> {
    let params = new HttpParams();
    // params = params.append('userObj', JSON.stringify(data.user));
    // params = params.append('userObjRP', data.randomPass);

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'user/insert', { recordObj: data.user }, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<User | string>;
  }

  updateUser(data): Observable<User | string> {
    let params = new HttpParams();
    // params = params.append('userObj', JSON.stringify(data.user));
    // params = params.append('userObjRP', data.pass);
    // params = params.append('wtd', data.wtd);

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'user/update', { recordObj: data.user }, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<User | string>;
  }

  deleteUser(data): Observable<User | string> {
    let params = new HttpParams();
    params = params.append('userId', data.userId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'user/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<User | string>;
  }

  authenticateUser(data): Observable<UserWithoutToken> {
    let params = new HttpParams();
    params = params.append('userName', data.userName);
    params = params.append('clientID', data.clientID);
    params = params.append('password', data.password);

    return this.http.get(environment.apiBaseUrl + 'user/auth', {
      params: params
    }) as Observable<User>;
  }

  /**
   * @deprecated use forgotPassword instead
   */
  newPasswordRequest(data): Observable<string> {
    let params = new HttpParams();
    params = params.append('email', data.email);
    params = params.append('clientID', data.clientID);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'user/passreset', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<string>;
  }

  forgotPassword(data: {
    clientID: number;
    email: string;
  }): Observable<number> {
    let params = new HttpParams();
    params = params.append('email', data.email);
    params = params.append('clientID', data.clientID);
    return this.http.get<number>(environment.apiBaseUrl + 'user/generatePassResetToken', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    });
  }

  resetPassword(data): Observable<string[]> {
    return this.http.post<string[]>(environment.apiBaseUrl + 'user/passReset', data);
  }

  verifyOTP(data: {
    userId: string;
    clientID: string;
    otp: number;
  }): Observable<HttpResponse<User>> {
    return this.http.post<User>(environment.apiBaseUrl + 'user/verifyOTP', data, { observe: 'response' });
  }


  resendOtp(data: {
    userName: string;
  }): Observable<HttpResponse<User>> {
    return this.http.post<User>(environment.apiBaseUrl + 'user/resendOTP', data, { observe: 'response' });
  }

  isLoggedIn() {
    try {
      if (localStorage.getItem("token")) {
        return true;
      }
      else {
        return false;
      }
    }
    catch {
      return false;
    }
  }

  //Aircraft API's ----------------------------------------------------------------------------------------------------

  insertAircraft(data): Observable<Aircraft | number> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/insert', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Aircraft | number>;
  }

  updateAircraft(data): Observable<Aircraft> {

    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/update', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Aircraft>;
  }

  updateAircraftDoc(data): Observable<Aircraft> {

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'aircraft/updatedoc', {
      recordObj: data
    }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Aircraft>;
  }

  deleteAircraft(data): Observable<Aircraft | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Aircraft | string>;
  }

  getAircraftByClientId(data): Observable<Aircraft[]> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Aircraft[]>;
  }

  getAircraftById(data): Observable<Aircraft> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Aircraft>;
  }

  insertAircraftSummary(data, aircraftObj): Observable<AircraftSummary> {
    let payload: any = {
      recordObj: data,
      aircraftObj: aircraftObj
    }

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'aircraft/insertSummary', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AircraftSummary>;
  }

  getAircraftSummaryByAicraftId(data): Observable<AircraftSummary[]> {
    let params = new HttpParams();
    params = params.append('wit', data.wit);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/listsummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AircraftSummary[]>;
  }

  deleteAircraftSummary(data): Observable<AircraftSummary | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'aircraft/deleteSummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AircraftSummary | string>;
  }

  getAircraftDocumentsByAssetSN(data): Observable<AssetDocument[]> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument[]>;
  }

  getAircraftDocumentsById(data): Observable<AssetDocument> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument>;
  }

  addAircraftDoc(data): Observable<AssetDocument | string> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/insertdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  getPresignedUrl(data: any): Observable<any> {
    const endPoint = `${environment.apiBaseUrl}aircraft/getUploadUrlDoc`;
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }
    const formData = new FormData();
    formData.append('clientId', clientID);
    formData.append('recordObj', JSON.stringify({ recordObj: data }));

    // const payload: any = {
    //   clientId: clientID,
    //   recordObj: data
    // };

    return this.http.post(endPoint, formData, {
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
      },
      reportProgress: true,
    }).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('last_activity');
          sessionStorage.clear();
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    );
  }

  addAircraftDocBulkUploadAsync(data: any, presignedUrl: string, file: File): Observable<any> {
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }

    console.log('Presigned URL:', presignedUrl);

    // First API call: Upload file to the presigned URL
    const uploadFileToPresignedUrl = from(fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })).pipe(
      catchError(error => {
        console.error('File upload failed:', error);
        return throwError(() => new Error('File upload failed'));
      })
    );

    data['uploadedFileUrl'] = presignedUrl.split('?')[0];

    // Second API call: Insert metadata to the backend
    const insertMetadataToBackend = (data: any): Observable<any> => {
      const endPoint = `${environment.apiBaseUrl}aircraft/insertuploaddoc`;
      const formData = new FormData();
      formData.append('clientId', clientID);
      formData.append('recordObj', JSON.stringify({ recordObj: data }));

      console.log('Endpoint:', endPoint);

      return this.http.post(endPoint, formData, {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
        },
      }).pipe(
        catchError(err => {
          if (err.status === 401) {
            // Handle 401 Unauthorized error
            localStorage.removeItem('user');
            localStorage.setItem('userFullName', "");
            localStorage.setItem('userName', "");
            localStorage.setItem('0', "");
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            localStorage.removeItem('last_activity');
            sessionStorage.clear();
            window.location.href = '/#/auth';
            window.location.reload();
          }
          return throwError(err);
        })
      );
    };

    // Chain the API calls
    return uploadFileToPresignedUrl.pipe(
      switchMap(() => insertMetadataToBackend(data)),
      catchError(error => {
        console.error('Error during the process:', error);
        return throwError(() => new Error('An error occurred during the file upload process'));
      })
    );
  }




  deleteAircraftDoc(data): Observable<AssetDocument | string> {

    let payload: any = {
      "recordId": data.recordId,
      "parentId": data.parentId
    }

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'aircraft/deletedoc', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  getAvionicList(data): Observable<Avionic[]> {
    let params = new HttpParams();
    params = params.append('assetSN', data.assetId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/listavionic', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Avionic[]>;
  }

  insertAvionicComponent(data): Observable<Avionic> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/insertavionic', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Avionic>;
  }
  updateAvionicComponent(data): Observable<Avionic> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/updateavionic', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Avionic>;
  }

  deleteAvionicComponent(data): Observable<Avionic | string> {
    let params = new HttpParams();
    params = params.append('recordId', data.recordId);
    params = params.append('parentId', data.parentId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/deleteavionic', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Avionic | string>;
  }

  getGalleyProvList(data): Observable<GalleyProv[]> {
    let params = new HttpParams();
    params = params.append('assetSN', data.assetId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/listGalley', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<GalleyProv[]>;
  }

  insertGalleyProvComponent(data): Observable<GalleyProv> {
    // let params = new HttpParams();
    // params = params.append('recordObj', JSON.stringify(data));
    let payload: any = {
      "recordObj": data,
    }

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'aircraft/insertGalley',payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<GalleyProv>;
  }
  updateGalleyProvComponent(data): Observable<GalleyProv> {
    // let params = new HttpParams();
    // params = params.append('recordObj', JSON.stringify(data));
    let payload: any = {
      "recordObj": data,
    }

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'aircraft/updateGalley',payload ,{
       headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<GalleyProv>;
  }

  deleteGalleyProvComponent(data): Observable<GalleyProv | string> {
    let params = new HttpParams();
    params = params.append('recordId', data.recordId);
    params = params.append('parentId', data.parentId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'aircraft/deleteGalley', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<GalleyProv | string>;
  }

  //Engine API's ------------------------------------------------------------------------------------------------------

  insertEngine(data): Observable<Engine | number> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/insert', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Engine | number>;
  }



  updateEngine(data,wtd?:string): Observable<Engine> {
    let params = new HttpParams();
    // params = params.append('recordObj', JSON.stringify(data));
    if(wtd){
      params = params.append('wtd', wtd);
      params = params.append('recordObj', JSON.stringify({aerEngId:data?.aerEngId}));
    }
    else{
      params = params.append('recordObj', JSON.stringify(data));
    }
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/update', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Engine>;
  }



  updateEngineDoc(data): Observable<Engine> {

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'engine/updatedoc', {
      recordObj: data
    }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Engine>;
  }

  deleteEngine(data): Observable<Engine | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Engine | string>;
  }

  clearAllMSNEngine(data): Observable<Engine> {
    let params = new HttpParams();
    params = params.append('recordObj', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/updateAll', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Engine>;
  }

  getEngineByClientId(data): Observable<Engine[]> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Engine[]>;
  }
  getEngineHistoryByEngineId(data): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('engine_id', data.engine_id);
    params = params.append('page', data.page);
    params = params.append('limit', data.limit);
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/listHistory', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any[]>;
  }
  getApuHistoryByEngineId(data): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('apu_id', data.apu_id);
    params = params.append('page', data.page);
    params = params.append('limit', data.limit);
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/listHistory', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any[]>;
  }

  getLandingGearHistoryByEngineId(data): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('landing_gear_id', data.landing_gear_id);
    params = params.append('page', data.page);
    params = params.append('limit', data.limit);
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/listHistory', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any[]>;
  }
  getPropellerHistoryByEngineId(data): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('propeller_id', data.propeller_id);
    params = params.append('page', data.page);
    params = params.append('limit', data.limit);
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/listHistory', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any[]>;
  }

  getEngineById(data): Observable<Engine> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Engine>;
  }

  insertEngineSummary(data): Observable<EngineSummary> {
    // let params = new HttpParams();
    // params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'engine/insertSummary', { recordObj: data }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<EngineSummary>;
  }

  getEngineSummaryByEngineId(data): Observable<EngineSummary[]> {
    let params = new HttpParams();
    params = params.append('wit', data.wit);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/listsummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<EngineSummary[]>;
  }

  deleteEngineSummary(data): Observable<EngineSummary | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'engine/deleteSummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<EngineSummary | string>;
  }

  getEngineDocumentsByAssetSN(data): Observable<AssetDocument[]> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument[]>;
  }

  getEngineDocumentsById(data): Observable<AssetDocument> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument>;
  }

  addEngineDoc(data): Observable<AssetDocument | string> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'engine/insertdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  getEnginePresignedUrl(data: any): Observable<any> {
    const endPoint = `${environment.apiBaseUrl}engine/getUploadUrlDoc`;
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }
    const formData = new FormData();
    formData.append('clientId', clientID);
    formData.append('recordObj', JSON.stringify({ recordObj: data }));

    // const payload: any = {
    //   clientId: clientID,
    //   recordObj: data
    // };

    return this.http.post(endPoint, formData, {
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
      },
      reportProgress: true,
    }).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('last_activity');
          sessionStorage.clear();
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    );
  }

  addEngineDocAsync(data: any,presignedUrl: string, file: File): Observable<AssetDocument | string> {

    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }

    console.log('Presigned URL:', presignedUrl);

    // First API call: Upload file to the presigned URL
    const uploadFileToPresignedUrl = from(fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })).pipe(
      catchError(error => {
        console.error('File upload failed:', error);
        return throwError(() => new Error('File upload failed'));
      })
    );

    data['uploadedFileUrl'] = presignedUrl.split('?')[0];

    // Second API call: Insert metadata to the backend
    const insertMetadataToBackend = (data: any): Observable<any> => {
      const endPoint = `${environment.apiBaseUrl}engine/insertuploaddoc`;
      const formData = new FormData();
      formData.append('clientId', clientID);
      formData.append('recordObj', JSON.stringify({ recordObj: data }));

      console.log('Endpoint:', endPoint);

      return this.http.post(endPoint, formData, {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
        },
      }).pipe(
        catchError(err => {
          if (err.status === 401) {
            // Handle 401 Unauthorized error
            localStorage.removeItem('user');
            localStorage.setItem('userFullName', "");
            localStorage.setItem('userName', "");
            localStorage.setItem('0', "");
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            localStorage.removeItem('last_activity');
            sessionStorage.clear();
            window.location.href = '/#/auth';
            window.location.reload();
          }
          return throwError(err);
        })
      );
    }
    return uploadFileToPresignedUrl.pipe(
      switchMap(() => insertMetadataToBackend(data)),
      catchError(error => {
        console.error('Error during the process:', error);
        return throwError(() => new Error('An error occurred during the file upload process'));
      })
    );
  }



  deleteEngineDoc(data): Observable<AssetDocument | string> {
    let payload: any = {
      "recordId": data.recordId,
      "parentId": data.parentId
    }

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'engine/deletedoc', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  //APU API's ----------------------------------------------------------------------------------------------------

  insertApu(data): Observable<APU | number> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/insert', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<APU | number>;
  }

  updateApu(data,wtd?:string): Observable<APU> {
    let params = new HttpParams();
    if(wtd){
      params = params.append('wtd', wtd);
      params = params.append('recordObj', JSON.stringify({aerApuId:data?.aerApuId}));
    }
    else{
      params = params.append('recordObj', JSON.stringify(data));
    }

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/update', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<APU>;
  }


  updateApuDoc(data): Observable<APU> {
    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'apu/updatedoc', {
      recordObj: data
    }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<APU>;
  }

  deleteAPU(data): Observable<APU | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<APU | string>;
  }

  clearAllMSNApu(data): Observable<APU> {
    let params = new HttpParams();
    params = params.append('recordObj', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/updateAll', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<APU>;
  }
  getApuByClientId(data): Observable<APU[]> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<APU[]>;
  }

  getApuById(data): Observable<APU> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<APU>;
  }

  insertApuSummary(data): Observable<ApuSummary> {
    // let params = new HttpParams();
    // params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'apu/insertSummary', { recordObj: data }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<ApuSummary>;
  }

  getApuSummaryByApuId(data): Observable<ApuSummary[]> {
    let params = new HttpParams();
    params = params.append('wit', data.wit);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/listsummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<ApuSummary[]>;
  }

  deleteAPUSummary(data): Observable<ApuSummary | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'apu/deleteSummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<ApuSummary | string>;
  }

  getApuDocumentsByAssetSN(data): Observable<AssetDocument[]> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument[]>;
  }

  getApuDocumentsById(data): Observable<AssetDocument> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument>;
  }

  addApuDoc(data): Observable<AssetDocument | string> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'apu/insertdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  getApuPresignedUrl(data: any): Observable<any> {
    const endPoint = `${environment.apiBaseUrl}apu/getUploadUrlDoc`;
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }
    const formData = new FormData();
    formData.append('clientId', clientID);
    formData.append('recordObj', JSON.stringify({ recordObj: data }));

    // const payload: any = {
    //   clientId: clientID,
    //   recordObj: data
    // };

    return this.http.post(endPoint, formData, {
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
      },
      reportProgress: true,
    }).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('last_activity');
          sessionStorage.clear();
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    );
  }

  addApuDocAsync(data: any,presignedUrl: string, file: File): Observable<AssetDocument | string> {
    this.updateAccesToken()
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }

    console.log('Presigned URL:', presignedUrl);

    // First API call: Upload file to the presigned URL
    const uploadFileToPresignedUrl = from(fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })).pipe(
      catchError(error => {
        console.error('File upload failed:', error);
        return throwError(() => new Error('File upload failed'));
      })
    );

    data['uploadedFileUrl'] = presignedUrl.split('?')[0];

    // Second API call: Insert metadata to the backend
    const insertMetadataToBackend = (data: any): Observable<any> => {
      const endPoint = `${environment.apiBaseUrl}apu/insertuploaddoc`;
      const formData = new FormData();
      formData.append('clientId', clientID);
      formData.append('recordObj', JSON.stringify({ recordObj: data }));

      console.log('Endpoint:', endPoint);

      return this.http.post(endPoint, formData, {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
        },
      }).pipe(
        catchError(err => {
          if (err.status === 401) {
            // Handle 401 Unauthorized error
            localStorage.removeItem('user');
            localStorage.setItem('userFullName', "");
            localStorage.setItem('userName', "");
            localStorage.setItem('0', "");
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            localStorage.removeItem('last_activity');
            sessionStorage.clear();
            window.location.href = '/#/auth';
            window.location.reload();
          }
          return throwError(err);
        })
      );
    }
    return uploadFileToPresignedUrl.pipe(
      switchMap(() => insertMetadataToBackend(data)),
      catchError(error => {
        console.error('Error during the process:', error);
        return throwError(() => new Error('An error occurred during the file upload process'));
      })
    );
  }


  deleteApuDoc(data): Observable<AssetDocument | string> {
    let payload: any = {
      "recordId": data.recordId,
      "parentId": data.parentId
    }

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'apu/deletedoc', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  //Landing Gear API's ------------------------------------------------------------------------------------------------------
  insertLGear(data): Observable<LandingGear | number> {
    let params = new HttpParams();
    data["tgtAsOfDate"] = data["tgtAsOfDate"].split("T")[0]
    data["manufactureDate"] = data["manufactureDate"].split("T")[0]
    console.log({ data })
    params = params.append('recordObj', JSON.stringify(data));


    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/insert', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGear | number>;
  }

  updateLGear(data,wtd?:string): Observable<LandingGear> {
    let params = new HttpParams();
    // console.log({ data })
    if(wtd){
      params = params.append('wtd', wtd);
      params = params.append('recordObj', JSON.stringify({aerLgId:data?.aerLgId}));
    }
    else{
      params = params.append('recordObj', JSON.stringify(data));
    }

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/update', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGear>;
  }


  updateLGearDoc(data): Observable<LandingGear> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'landinggear/updatedoc', { recordObj: data }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGear>;
  }

  deleteLGear(data): Observable<LandingGear | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGear | string>;
  }

  clearAllMSNLGear(data): Observable<LandingGear> {
    let params = new HttpParams();
    params = params.append('recordObj', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/updateAll', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGear>;
  }

  getLandingGearByClientId(data): Observable<LandingGear[]> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGear[]>;
  }

  getLandingGearById(data): Observable<LandingGear> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGear>;
  }

  insertLGearSummary(data): Observable<LandingGearSummary> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'landinggear/insertSummary', { recordObj: data }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGearSummary>;
  }

  getLGearSummaryByLGId(data): Observable<LandingGearSummary[]> {
    let params = new HttpParams();
    params = params.append('wit', data.wit);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/listsummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGearSummary[]>;
  }

  deleteLGearSummary(data): Observable<LandingGearSummary | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'landinggear/deleteSummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<LandingGearSummary | string>;
  }

  getLandingGearDocumentsByAssetSN(data): Observable<AssetDocument[]> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument[]>;
  }

  getLandingGearDocumentsById(data): Observable<AssetDocument> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument>;
  }

  addLandingGearDoc(data): Observable<AssetDocument | string> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'landinggear/insertdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  getLandingGearPresignedUrl(data: any): Observable<any> {
    const endPoint = `${environment.apiBaseUrl}landinggear/getUploadUrlDoc`;
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }
    const formData = new FormData();
    formData.append('clientId', clientID);
    formData.append('recordObj', JSON.stringify({ recordObj: data }));

    // const payload: any = {
    //   clientId: clientID,
    //   recordObj: data
    // };

    return this.http.post(endPoint, formData, {
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
      },
      reportProgress: true,
    }).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('last_activity');
          sessionStorage.clear();
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    );
  }

  addLandingGearDocAsync(data: any,presignedUrl:string, file: File): Observable<AssetDocument | string> {
    this.updateAccesToken()
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }

    console.log('Presigned URL:', presignedUrl);

    // First API call: Upload file to the presigned URL
    const uploadFileToPresignedUrl = from(fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })).pipe(
      catchError(error => {
        console.error('File upload failed:', error);
        return throwError(() => new Error('File upload failed'));
      })
    );

    data['uploadedFileUrl'] = presignedUrl.split('?')[0];

    // Second API call: Insert metadata to the backend
    const insertMetadataToBackend = (data: any): Observable<any> => {
      const endPoint = `${environment.apiBaseUrl}landinggear/insertuploaddoc`;
      const formData = new FormData();
      formData.append('clientId', clientID);
      formData.append('recordObj', JSON.stringify({ recordObj: data }));

      console.log('Endpoint:', endPoint);

      return this.http.post(endPoint, formData, {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
        },
      }).pipe(
        catchError(err => {
          if (err.status === 401) {
            // Handle 401 Unauthorized error
            localStorage.removeItem('user');
            localStorage.setItem('userFullName', "");
            localStorage.setItem('userName', "");
            localStorage.setItem('0', "");
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            localStorage.removeItem('last_activity');
            sessionStorage.clear();
            window.location.href = '/#/auth';
            window.location.reload();
          }
          return throwError(err);
        })
      );
    }
    return uploadFileToPresignedUrl.pipe(
      switchMap(() => insertMetadataToBackend(data)),
      catchError(error => {
        console.error('Error during the process:', error);
        return throwError(() => new Error('An error occurred during the file upload process'));
      })
    );
  }

  deleteLandingGearDoc(data): Observable<AssetDocument | string> {
    let payload: any = {
      "recordId": data.recordId,
      "parentId": data.parentId
    };

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'landinggear/deletedoc', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  //Propeller API's ------------------------------------------------------------------------------------------------------
  insertProp(data): Observable<Propeller | number> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/insert', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller | number>;
  }

  updateProp(data,wtd?:string): Observable<Propeller> {
    let params = new HttpParams();
    if(wtd){
      params = params.append('wtd', wtd);
      params = params.append('recordObj', JSON.stringify({aerPropId:data?.aerPropId}));
    }
    else{
      params = params.append('recordObj', JSON.stringify(data));
    }

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/update', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller>;
  }

  updatePropDoc(data): Observable<Propeller> {

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'propeller/updatedoc', { recordObj: data }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller>;
  }

  deleteProp(data): Observable<Propeller | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller | string>;
  }

  clearAllMSNProp(data): Observable<Propeller> {
    let params = new HttpParams();
    params = params.append('recordObj', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/updateAll', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller>;
  }

  getPropByClientId(data): Observable<Propeller[]> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller[]>;
  }

  getPropById(data): Observable<Propeller> {
    let params = new HttpParams();
    params = params.append('clientID', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller>;
  }

  insertPropSummary(data): Observable<PropellerSummary> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'propeller/insertSummary', { recordObj: data }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<PropellerSummary>;
  }

  getPropSummaryByPropId(data): Observable<PropellerSummary[]> {
    let params = new HttpParams();
    params = params.append('wit', data.wit);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/listsummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<PropellerSummary[]>;
  }

  deletePropSummary(data): Observable<PropellerSummary | string> {
    let params = new HttpParams();
    params = params.append('recordId', data);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'propeller/deleteSummary', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<PropellerSummary | string>;
  }

  getPropDocumentsByAssetSN(data): Observable<AssetDocument[]> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument[]>;
  }

  getPropDocumentsById(data): Observable<AssetDocument> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument>;
  }

  addPropDoc(data): Observable<AssetDocument | string> {
    let params = new HttpParams();
    params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'propeller/insertdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  getPropellerPresignedUrl(data: any): Observable<any> {
    const endPoint = `${environment.apiBaseUrl}propeller/getUploadUrlDoc`;
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }
    const formData = new FormData();
    formData.append('clientId', clientID);
    formData.append('recordObj', JSON.stringify({ recordObj: data }));

    // const payload: any = {
    //   clientId: clientID,
    //   recordObj: data
    // };

    return this.http.post(endPoint, formData, {
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
      },
      reportProgress: true,
    }).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('last_activity');
          sessionStorage.clear();
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    );
  }

  addPropDocAsync(data: any,presignedUrl:string, file: File): Observable<AssetDocument | string> {
    this.updateAccesToken()
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }

    console.log('Presigned URL:', presignedUrl);

    // First API call: Upload file to the presigned URL
    const uploadFileToPresignedUrl = from(fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })).pipe(
      catchError(error => {
        console.error('File upload failed:', error);
        return throwError(() => new Error('File upload failed'));
      })
    );

    data['uploadedFileUrl'] = presignedUrl.split('?')[0];

    // Second API call: Insert metadata to the backend
    const insertMetadataToBackend = (data: any): Observable<any> => {
      const endPoint = `${environment.apiBaseUrl}propeller/insertuploaddoc`;
      const formData = new FormData();
      formData.append('clientId', clientID);
      formData.append('recordObj', JSON.stringify({ recordObj: data }));

      console.log('Endpoint:', endPoint);

      return this.http.post(endPoint, formData, {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
        },
      }).pipe(
        catchError(err => {
          if (err.status === 401) {
            // Handle 401 Unauthorized error
            localStorage.removeItem('user');
            localStorage.setItem('userFullName', "");
            localStorage.setItem('userName', "");
            localStorage.setItem('0', "");
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            localStorage.removeItem('last_activity');
            sessionStorage.clear();
            window.location.href = '/#/auth';
            window.location.reload();
          }
          return throwError(err);
        })
      );
    }
    return uploadFileToPresignedUrl.pipe(
      switchMap(() => insertMetadataToBackend(data)),
      catchError(error => {
        console.error('Error during the process:', error);
        return throwError(() => new Error('An error occurred during the file upload process'));
      })
    );
  }

  deletePropDoc(data): Observable<AssetDocument | string> {
    let payload: any = {
      "recordId": data.recordId,
      "parentId": data.parentId
    };

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'propeller/deletedoc', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }

  //Document Type API's -----------------------------------------------------------------------------------------------
  getDocTypeView(data): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('wit', 'clientId');
    params = params.append('wrecordId', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'doctype/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any[]>;
  }

  getDocType(data): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('wrecordId', data.id);
    params = params.append('wit', data.wit);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'doctype/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any[]>;
  }

  getDocTypeCategories(data): Observable<DocTypeCategory[]> {
    let params = new HttpParams();
    params = params.append('wrecordId', data.id);
    params = params.append('wit', data.wit);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'doctype/listCat', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocTypeCategory[]>;
  }

  getLogBook(data): Observable<DocTypeCategory[]> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('limit', data.limit);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'log/list', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocTypeCategory[]>;
  }

  findLogBook(data): Observable<DocTypeCategory[]> {
    let params = new HttpParams();
    params = params.append('id', data);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'log/listLogById', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocTypeCategory[]>;
  }


  addLogPage(recordObj): Observable<DocumentType | string> {
    let params = new HttpParams();

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'log/insert', recordObj, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }
  deleteAirWorthinessDirective(id: number): Observable<DocumentType | string> {
    let params = new HttpParams();
    // params = params.append('wit', 'clientId');
    params = params.append('wrecordId', id);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'airworthiness-directive/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  updateAirWorthinessDirective(data): Observable<Propeller> {

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'airworthiness-directive/update',  data , {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller>;
  }
  addAirWorthinessDirective(recordObj): Observable<DocumentType | string> {

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'airworthiness-directive/insert', recordObj, {
       headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  deleteStatusList(id: number): Observable<DocumentType | string> {
    let params = new HttpParams();
    // params = params.append('wit', 'clientId');
    params = params.append('wrecordId', id);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'asset-maintenance-mapping/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  updateStatusList(data): Observable<Propeller> {

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'asset-maintenance-mapping/update',  data , {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller>;
  }

  addStatusList(recordObj): Observable<DocumentType | string> {

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'asset-maintenance-mapping/insert', recordObj, {
       headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  updateAirWorthinessDirectivesTasks(data): Observable<Propeller> {

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'compliance/update',  data , {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Propeller>;
  }

  addAirWorthinessDirectivesTasks(recordObj): Observable<DocumentType | string> {

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'compliance/insert', recordObj, {
       headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  //Airworthiness DataBase API's
  deleteAirWorthinessDirectivesTasks(id: number): Observable<DocumentType | string> {
    let params = new HttpParams();
    // params = params.append('wit', 'clientId');
    params = params.append('wrecordId', id);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'compliance/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }
  getAirWorthinessDirectivesTasks(assetMaintenanceMappingId): Observable<Airdirective[]> {

    let params = new HttpParams();
    // params = params.append('wit', 'clientId');
    params = params.append('assetMaintenanceMappingId', assetMaintenanceMappingId);
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'compliance/listByAssetMaintenanceMapping', {
      params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Airdirective[]>;
  }
  getStatusList(assetTypeId,assetId): Observable<Airdirective[]> {

    let params = new HttpParams();
    params = params.append('assetId', assetId);
    params = params.append('assetTypeId', assetTypeId);
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'asset-maintenance-mapping/listByAsset', {
      params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<Airdirective[]>;
  }

  addDocType(recordObj): Observable<DocumentType | string> {
    let params = new HttpParams();

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'doctype/insertHeader', recordObj, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  addDocTypeCategory(recordObj): Observable<DocumentType | string> {
    let params = new HttpParams();

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'doctype/insertCat', recordObj, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  addDocTypeType(recordObj): Observable<DocumentType | string> {
    let params = new HttpParams();

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'doctype/insert?record', recordObj, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  updateDocType(data): Observable<DocumentType | string> {
    let params = new HttpParams();

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'doctype/updateHeader', data, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  updateDocTypeCategory(data): Observable<DocumentType | string> {
    let params = new HttpParams();

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'doctype/updateCat', data, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  updateDocTypeType(data): Observable<DocumentType | string> {
    let params = new HttpParams();

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'doctype/update?record=', data, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  //Role Delete
  deleteRole(id: number): Observable<DocumentType | string> {
    let params = new HttpParams();
    // params = params.append('wit', 'clientId');
    params = params.append('wrecordId', id);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'role/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }


  // Delete User from Role
  deleteUserFromRole(payload: any): Observable<DocumentType | string> {


    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'user/detachRole', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }


  //Add User To Role
  addUserToRole(payload: any): Observable<DocumentType | string> {

    // params = params.append('wit', 'clientId');

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'user/attachRole', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }


  //Add Role
  addRole(payload: any): Observable<DocumentType | string> {

    // params = params.append('wit', 'clientId');

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'role/insert', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  // Update Role

  updateRole(data): Observable<DocumentType | string> {
    let params = new HttpParams();

    this.updateAccesToken()
    return this.http.put(environment.apiBaseUrl + 'role/update', data, {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }

  deleteDocType(id: number): Observable<DocumentType | string> {
    let params = new HttpParams();
    params = params.append('wit', 'clientId');
    params = params.append('wrecordId', id);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'doctype/deleteHeader', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }
  deleteDocTypeCategory(id: number): Observable<DocumentType | string> {
    let params = new HttpParams();
    params = params.append('wit', 'all');
    params = params.append('wrecordId', id);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'doctype/deleteCat', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }
  deleteDocTypeType(id: number): Observable<DocumentType | string> {
    let params = new HttpParams();
    params = params.append('wit', 'clientId');
    params = params.append('wrecordId', id);

    this.updateAccesToken()
    return this.http.delete(environment.apiBaseUrl + 'doctype/delete', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<DocumentType | string>;
  }


  // Api call to change password
  changePasswordApi(data): Observable<User> {
    const headers = new HttpHeaders({
      "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
    });

    return this.http.post<User>(environment.apiBaseUrl + 'user/passResetAuthenticated', {
      old_password: data.old_password,
      password: data.password,
      userId: data.id,
      clientID: data.clientID
    }, {
      headers: headers,
    }).pipe(
      catchError(err => {
        if (err.status === 401) {
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    );
  }



  addFeedbackApi(data: any): Observable<any | string> {
    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'feedback/insert'


    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('POST', endPoint, data, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }



  updateFeedbackApi(data: any, id: number, feedbackId: string): Observable<any | string> {
    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'feedback/update?author_id=' + id;


    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('PUT', endPoint, { ...data, id: feedbackId }, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }





  getAllFeedbackApi(clientID: string, id: string): Observable<any | string> {
    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'feedback/list?clientID=' + clientID + '&id=' + id


    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    let params = new HttpParams()
    params.append("clientID", clientID)

    const req = new HttpRequest('GET', endPoint, {
      params: params,
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }

  getDashboardStats(clientID: string): Observable<any | string> {
    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'dashboard/listAssetCount?clientID=' + clientID


    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('GET', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }



  getFeedbackByIdApi(clientID: string, id: number): Observable<any | string> {
    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'feedback/list'


    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    let params = new HttpParams()
    params.append("clientID", clientID)
    params.append("id", id)

    const req = new HttpRequest('GET', endPoint, {
      params,
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }



  postNewcommentApi(data: any): Observable<any | string> {
    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'comment/insert'


    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('POST', endPoint, data, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }



  getCommentsApi(feedbackID: string, clientID: string): Observable<any | string> {
    this.updateAccesToken()
    let endPoint: string = environment.apiBaseUrl + 'comment/list?feedbackID=' + feedbackID;
    if (clientID) {
      endPoint = endPoint + '&clientID=' + clientID
    }

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('GET', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }


  updateAccesToken(): void {
    let endPoint: string = environment.apiBaseUrl + 'user/refreshToken';
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('POST', endPoint, {
      refresh_token: localStorage.getItem('refresh')
    }, {
      headers: headers,
      reportProgress: true,
    });

    const res = this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any>;

    res.subscribe(data => {
      if (data?.body?.access_token) {
        localStorage.setItem("token", data?.body?.access_token)
        localStorage.setItem("last_activity", new Date().getTime().toString())
      }
    })
  }




  getWasabiStats(clientID: string): Observable<any | string> {
    this.updateAccesToken()
    let endPoint: string = environment.apiBaseUrl + 'dashboard/listWasabiFolder';
    if (clientID) {
      endPoint = endPoint + '?clientID=' + clientID
    } else {
      endPoint = endPoint + '?clientID=all'
    }

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('GET', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }





  getNewOTPToken(userName: string): Observable<any | string> {
    let client = JSON.parse(localStorage.getItem("client"))?.clientID
    let endPoint: string = environment.apiBaseUrl + 'user/resendOTP?userName=' + userName + "&clientID=" + client;

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('GET', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    )
  }



  // COMPONENTS PROFILE
  postNewComponentsProfileApi(data: any): Observable<any | string> {

    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'component/insertProfile'
    // const formData: FormData = new FormData();

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('POST', endPoint, data, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }


  putComponentsProfileApi(data: any): Observable<any | string> {

    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'component/updateProfile'
    // const formData: FormData = new FormData();

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('PUT', endPoint, data, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }

  getComponentsProfilesApi(): Observable<any | string> {
    let endPoint: string = environment.apiBaseUrl + 'component/listProfile';

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('GET', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    )
  }

  getComponentProfileByIdApi(id: string): Observable<any | string> {
    let endPoint: string = environment.apiBaseUrl + 'component/listProfileById?id=' + id;

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('GET', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    )
  }

  deleteComponentProfileByIdApi(id: string): Observable<any | string> {
    let endPoint: string = environment.apiBaseUrl + 'component/deleteProfile?id=' + id;

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('DELETE', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    )
  }


  postNewComponentApi(data: any): Observable<any | string> {

    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'component/insert'
    // const formData: FormData = new FormData();

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('POST', endPoint, data, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }

  putComponentApi(data: any): Observable<any | string> {

    this.updateAccesToken()
    const endPoint: string = environment.apiBaseUrl + 'component/update'
    // const formData: FormData = new FormData();

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('PUT', endPoint, data, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    ) as Observable<any | string>;
  }

  insertComponentSummary(data): Observable<ApuSummary> {
    // let params = new HttpParams();
    // params = params.append('recordObj', JSON.stringify(data));

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'component/insertSummary', { recordObj: data }, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<ApuSummary>;
  }

  getComponentApi(): Observable<any | string> {
    let endPoint: string = environment.apiBaseUrl + 'component/list';

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('GET', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    )
  }
  getComponentHistoryByComponentId(data): Observable<any | string> {
    let params = new HttpParams();
    params = params.append('component_id', data.component_id);
    params = params.append('page', data.page);
    params = params.append('limit', data.limit);
    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'component/listHistory', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<any[]>;
  }

  getComponentByIdApi(id: string): Observable<any | string> {
    let endPoint: string = environment.apiBaseUrl + 'component/listById?id=' + id;

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('GET', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    )
  }

  deleteComponentByIdApi(id: string): Observable<any | string> {
    let endPoint: string = environment.apiBaseUrl + 'component/delete?id=' + id;

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    });

    const req = new HttpRequest('DELETE', endPoint, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");;
          sessionStorage.clear();;
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    )
  }

  getComponentDocumentsByAssetSN(data: { clientId: string, recordId: number }): Observable<AssetDocument[]> {
    let params = new HttpParams();
    params = params.append('assetSN', data.clientId);
    params = params.append('recordID', data.recordId);

    this.updateAccesToken()
    return this.http.get(environment.apiBaseUrl + 'component/listdoc', {
      params: params, headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument[]>;
  }

  getComponentPresignedUrl(data: any): Observable<any> {
    const endPoint = `${environment.apiBaseUrl}component/getUploadUrlDoc`;
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }
    const formData = new FormData();
    formData.append('clientId', clientID);
    formData.append('recordObj', JSON.stringify({ recordObj: data }));

    // const payload: any = {
    //   clientId: clientID,
    //   recordObj: data
    // };

    return this.http.post(endPoint, formData, {
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
      },
      reportProgress: true,
    }).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('last_activity');
          sessionStorage.clear();
          window.location.href = '/#/auth';
          window.location.reload();
        }
        return throwError(err);
      })
    );
  }

  addComponentDocAsync(data: any,presignedUrl:string, file: File): Observable<AssetDocument | string> {
    this.updateAccesToken()
    const clientID = JSON.parse(localStorage.getItem('client'))?.clientID;

    if (!clientID) {
      return throwError(() => new Error('Client ID is not available'));
    }

    console.log('Presigned URL:', presignedUrl);

    // First API call: Upload file to the presigned URL
    const uploadFileToPresignedUrl = from(fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })).pipe(
      catchError(error => {
        console.error('File upload failed:', error);
        return throwError(() => new Error('File upload failed'));
      })
    );

    data['uploadedFileUrl'] = presignedUrl.split('?')[0];

    // Second API call: Insert metadata to the backend
    const insertMetadataToBackend = (data: any): Observable<any> => {
      const endPoint = `${environment.apiBaseUrl}component/insertuploaddoc`;
      const formData = new FormData();
      formData.append('clientId', clientID);
      formData.append('recordObj', JSON.stringify({ recordObj: data }));

      console.log('Endpoint:', endPoint);

      return this.http.post(endPoint, formData, {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
        },
      }).pipe(
        catchError(err => {
          if (err.status === 401) {
            // Handle 401 Unauthorized error
            localStorage.removeItem('user');
            localStorage.setItem('userFullName', "");
            localStorage.setItem('userName', "");
            localStorage.setItem('0', "");
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            localStorage.removeItem('last_activity');
            sessionStorage.clear();
            window.location.href = '/#/auth';
            window.location.reload();
          }
          return throwError(err);
        })
      );
    }
    return uploadFileToPresignedUrl.pipe(
      switchMap(() => insertMetadataToBackend(data)),
      catchError(error => {
        console.error('Error during the process:', error);
        return throwError(() => new Error('An error occurred during the file upload process'));
      })
    );
  }

  deleteComponentDoc(data): Observable<AssetDocument | string> {
    let payload: any = {
      "recordId": data.recordId,
      "parentId": data.parentId
    };

    this.updateAccesToken()
    return this.http.post(environment.apiBaseUrl + 'component/deletedoc', payload, {
      headers: {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
      }
    }).pipe(catchError(err => {
      if (err.status === 401) {
        // Handle 401 Unauthorized error
        localStorage.removeItem('user');
        localStorage.setItem('userFullName', "");
        localStorage.setItem('userName', "");
        localStorage.setItem('0', "");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("last_activity");
        sessionStorage.clear();
        window.location.href = '/#/auth';
        window.location.reload();
      }
      return throwError(err)
    })) as Observable<AssetDocument | string>;
  }


  goBack(): void {
    this.location.back();
  }
  goForward(): void {
    this.location.forward();
  }
   openErrorSnackBar(message: string, action: string = '') {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['grey-snackbar', 'login-snackbar']
    });
  }
  public openSnackBar(message: string, action: string = '') {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['green-snackbar', 'login-snackbar']
    });
  }


}
