/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ChangePasswordComponent } from './pages/auth/change-password/change-password.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    data : {
      title: 'Home'
    },
    canActivate:[AuthGuard]
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule),
    data : {
      title: 'Auth'
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
    data : {
      title: 'Settings'
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule),
    data : {
      title: 'User'
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'assets',
    loadChildren: () => import('./pages/assets/assets.module').then( m => m.AssetsPageModule),
    data : {
      title: 'Assets'
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'components',
    loadChildren: () => import('./pages/assets/asset-components/asset-components.module').then( m => m.AssetComponentsPageModule),
    data : {
      title: 'Components'
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule),
    data : {
      title: 'Reports'
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'statuslist',
    loadChildren: () => import('./pages/statuslist/statuslist.module').then( m => m.StatuslistPageModule),
    data : {
      title: 'Status List'
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'airdirectives',
    loadChildren: () => import('./pages/airdirectives/airdirectives.module').then( m => m.AirdirectivesPageModule),
    data : {
      title: 'Airworthiness Directives'
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'clients',
    loadChildren: () => import('./pages/clients/clients.module').then( m => m.ClientsPageModule),
    data : {
      title: 'Clients'
    },
    canActivate:[AuthGuard]
  },
   {
    path: 'air-repair/:aircraftId',
    loadChildren: () => import('./pages/statuslists/air-repair/air-repair.module').then( m => m.AirRepairPageModule)
  },
  {
    path: 'air-ad/:aircraftId',
    loadChildren: () => import('./pages/statuslists/air-ad/air-ad.module').then( m => m.AirAdPageModule)
  },
  {
    path: 'air-cpf/:aircraftId',
    loadChildren: () => import('./pages/statuslists/air-cpf/air-cpf.module').then( m => m.AirCPFPageModule)
  },
  {
    path: 'air-hard-tcpf/:aircraftId',
    loadChildren: () => import('./pages/statuslists/air-hard-tcpf/air-hard-tcpf.module').then( m => m.AirHardTCPFPageModule)
  },
  {
    path: 'air-mod-operator/:aircraftId',
    loadChildren: () => import('./pages/statuslists/air-mod-operator/air-mod-operator.module').then( m => m.AirModOperatorPageModule)
  },
  {
    path: 'air-mpd/:aircraftId',
    loadChildren: () => import('./pages/statuslists/air-mpd/air-mpd.module').then( m => m.AirMPDPageModule)
  },
  {
    path: 'air-service-bulletin/:aircraftId',
    loadChildren: () => import('./pages/statuslists/air-service-bulletin/air-service-bulletin.module').then( m => m.AirServiceBulletinPageModule)
  },
  {
    path: 'chpw',
    canActivate: [AuthGuard],
    component: ChangePasswordComponent
  },
  {
    path: 'feedback',
    canActivate: [AuthGuard],
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/role-permission/user-role.module').then( m => m.UserRoleModule),
    data : {
      title: 'Roles'
    },
    canActivate:[AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/log-page/log-page.module').then( m => m.LogPageModule),
    data : {
      title: 'Logs Book'
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'air-task/:assetMaintenanceMappingId',
    loadChildren: () => import('./pages/statuslists/air-task/air-task.module').then( m => m.AirTaskModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
