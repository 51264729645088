/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: true,
  apiBaseUrl: 'https://admin.aer-survey.com/API/index.php/',
  WSaccessKeyId: 'H1T4PP3YFN8RZ6BJLDYD',
  WSsecretAccessKey: '2FfsVnPQ4tPFZOfyDp0U0guz73PmOA8McgPeb3zO',
  WSregion: 'us-east-1',
  WSendpoint: 'https://s3.wasabisys.com',
  WSBucket: 'airtrends-dev',
  siteURL: 'aer-survey.com'
};
