/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-trailing-spaces */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, forkJoin, from, of, throwError } from 'rxjs';
import { Client } from './models/client.model';
import { ApiService } from './services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from './models/user.model';
import { Module } from 'src/app/models/module.model';
import { catchError, finalize, first, map, switchMap, take, tap } from 'rxjs/operators';
import { AppService } from './app.service';

type MenuItem = {
  title: string;
  url: string;
  iconName: string;
  moduleId: string;
  children?: MenuItem[];
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loadPage: boolean = false;
  authSub: Subscription;
  clientSub: Subscription;
  isUserLoggedIn: boolean = false;

  currentUser: User | undefined;
  modules: Module[] | undefined;
  menuItems: MenuItem[] | undefined;

  intervalId: any;
  showTimerPopUp: boolean = false;



  // password expiry countdown
  showTimer: boolean = false;
  countdownSeconds: number; // Total countdown time in seconds
  hours: number;
  minutes: number;
  seconds: number;

  private subscription: Subscription;
  private subscription2: Subscription;
  constructor(private apiService: ApiService, private router: Router,
    private appService: AppService
  ) {


    this.isUserLoggedIn = false

    if (localStorage.getItem("refresh") && localStorage.getItem("last-activity")) {
      this.startChecking()
    }

    if (localStorage.getItem("user")) {

      let parsed: any = JSON.parse(localStorage.getItem("user"))
      let expDate: string = parsed?.pwd_exp_at ? parsed?.pwd_exp_at : "";
      if (expDate) {
        const expDateObj = new Date(expDate);
        const now = new Date();
        const dateDiff = expDateObj.getTime() - now.getTime();
        const days = Math.floor(dateDiff / (3600 * 24 * 1000));

        if (days == 0) {
          const dateDiffSeconds = Math.floor(dateDiff / 1000);
          this.countdownSeconds = dateDiffSeconds; // Example: 1 hour
          this.showTimer = true
        } else if (dateDiff <= 0) {
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");
          sessionStorage.clear();
          window.location.href = "/#/auth"
        }
      }


    }
  }

  ngOnDestroy(): void {

    if (this.clientSub !== null && this.clientSub !== undefined) {
      this.clientSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }




  startChecking(): void {
    let last_activity = localStorage.getItem("last_activity") ? parseInt(localStorage.getItem("last_activity")) : null
    if (last_activity) {
      // Define the threshold in milliseconds
      const fourteenMinutesAndThirtySecondsInMillis = (14 * 60 * 1000) + (30 * 1000);
      const fifteenmilliseconds = (15 * 60 * 1000);

      this.intervalId = setInterval(() => {
        // Get the current timestamp
        const currentTimestamp = Date.now();

        // Calculate the difference
        const differenceInMillis = currentTimestamp - last_activity;


        // Check if the difference is greater than or equal to 14 minutes and 30 seconds
        if (differenceInMillis >= fourteenMinutesAndThirtySecondsInMillis && differenceInMillis < fifteenmilliseconds) {
          this.showTimerPopUp = true
        }
        if (differenceInMillis >= fifteenmilliseconds) {
          this.showTimerPopUp = false
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");
          sessionStorage.clear();
          this.appService.triggerRemoveMenu();
          this.router.navigate(["/auth"])
        }

        else {
        }
      }, 1000); // 1000 milliseconds = 1 second
    } else {
      this.appService.triggerStartSessionTimer()
    }
  }



  goTo(pageUrl: string) {
    this.router.navigate(['/' + pageUrl]);
  }

  async extendSession(): Promise<void> {
    try {
      await this.apiService.updateAccesToken()
      this.showTimerPopUp = false
      clearInterval(this.intervalId);
      localStorage.removeItem("last_activity")
      this.appService.triggerStartSessionTimer()
    } catch (err) {
    }
  }

  ngOnInit() {
    this.loadMenuItem()
    this.isLoggedIn()
    this.subscription = this.appService.triggerEvent$.subscribe(() => {
      this.isLoggedIn();
      this.loadMenuItem();
    });

    this.subscription = this.appService.triggerLogout$.subscribe(() => {
      this.menuItems = []
      this.isLoggedIn();
    });

    this.subscription = this.appService.triggerSessionTimer$.subscribe(() => {
      this.startChecking()
    });


    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 1000); // Update every second
  }


  loadMenuItem(): void {
    const subdomainObservable = this.getSubdomain();
    const currentUserObservable = this.apiService.user.pipe(
      first(),
      switchMap((user: User) => {
        this.currentUser = user;
        if (this.currentUser) {
          this.isUserLoggedIn = true;
          return this.getMenuItems();
        }
        return of<null>(null);
      }),
      tap((menuItems) => {
        if (menuItems) this.menuItems = menuItems;
      })
    );

    forkJoin([subdomainObservable, currentUserObservable])
      .pipe(
        tap(() => {
          this.loadPage = true;
        })
      )
      .subscribe({
        error(e) {
          console.error('Error starting the app', e);
        },
      });



    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 1000); // Update every second
  }



  updateTime() {
    if (this.countdownSeconds > 0) {
      const hours = Math.floor(this.countdownSeconds / (3600));
      const minutes = Math.floor((this.countdownSeconds % 3600) / 60);
      const seconds = this.countdownSeconds % 60;

      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;

      this.countdownSeconds--; // Decrement countdown timer
      localStorage.setItem("expiry_time", this.countdownSeconds.toString())
    } else {
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
    }
  }

  public getSubdomain(): Observable<void> {
    //console.log('checking for subdomain');
    let subdomain = '';
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'localhost' ||
      domain.split('.')[0] === 'lvh'
    ) {
      subdomain = 'demo';
    } else {
      subdomain = domain.split('.aer-survey.com')[0];
    }

    return this.apiService.getClient(subdomain).pipe(
      map((res: Client | string) => {
        if (res != null) {
          try {
            const tempClient = res[0] as Client;
            this.apiService.subDomain.next(tempClient.subdomain);
            this.apiService.client.next(tempClient);
          } catch {
            // console.log(res);
          }
        }
      }),
      catchError((error) => {
        console.error('Error getting subdomain', error);
        return throwError(error);
      })
    );
  }

  isLoggedIn(): boolean {
    const response = localStorage.getItem("token");
    if (response) {
      return true
    } else {
      return false;
    }
  }

  getMenuItems(): Observable<MenuItem[]> {
    const userData = {
      roleId: this.currentUser.roleId,
      clientID: this.currentUser.clientID,
    };
    const modulePages = ['home', 'settings'];
    const getModulesRequests = modulePages.map((page) => this.apiService.getModules({ ...userData, page }));

    return forkJoin(getModulesRequests).pipe(
      map((responses) => {
        const modules = responses.reduce((acc, modules) => [...acc, ...modules], []);

        let menuItems = modules
          .filter((m) => m.parentPage === 'home')
          .map((m) => ({
            title: m.moduleName,
            url: m.url,
            iconName: this.getModuleIcon(m),
            moduleId: this.parseModuleId(m.moduleId),
            children: modules
              .filter((m2) => m2.parentPage === moduleNameToPageNameMap.get(m.moduleName))
              .map((m2) => ({
                title: m2.moduleName,
                url: m2.url,
                iconName: this.getModuleIcon(m2),
                moduleId: this.parseModuleId(m2.moduleId),
              })),
          }));

        // Add Dashboard at the beginning
        let home = {
          title: "Dashboard",
          url: "/home",
          iconName: "home",
          moduleId: "home-0",
          children: []
        };
        menuItems.splice(0, 0, home);

        // Add airworthinessTasks after Dashboard
        let airworthinessTasks = {
          title: "Air Maintenance Tasks",
          url: '#',
          iconName: "airplane-outline",
          moduleId: "air-task-12",
          disable:true,
          children: [
            {
              title: "AirWorthiness Directive",
              url: "/airdirectives",
              iconName: "document",
              moduleId: "home-0",
            }
          ]
        };
        menuItems.splice(1, 0, airworthinessTasks);

        // Move Settings to the end
        const settingsIndex = menuItems.findIndex((m) => m.title === 'Settings');
        if (settingsIndex !== -1) {
          const settings = menuItems.splice(settingsIndex, 1)[0];
          menuItems.push(settings);
        }

        return menuItems
          .filter(m => m.title !== 'Airworthiness Directives')
          .filter(m => m.title.toLowerCase() !== 'reports')
          .filter(m => m.title.toLowerCase() !== 'status list');
      }),
      catchError((error) => {
        console.error('Error getting menu items', error);
        return throwError(error);
      })
    );
  }


  private parseModuleId(moduleId: string | number): string {
    return typeof moduleId === 'string' ? moduleId : moduleId.toString();
  }

  private getModuleIcon(module: Module) {
    const overrideIcon = modules.get(this.parseModuleId(module.id))?.iconName;
    if (overrideIcon) {
      return overrideIcon;
    }
    return module.iconName;
  }
}

const moduleNameToPageNameMap = new Map([
  ['home', 'home'],
  ['Settings', 'settings'],
]);

const modules = new Map([
  ["0", { moduleName: "Home", url: "/home", iconName: "home-outline" }],
  ["10", { moduleName: "Settings", url: "/settings", iconName: "settings-outline" }],
  ["11", { moduleName: "Users", url: "/settings/users", iconName: "people-outline" }],
  ["12", { moduleName: "Document Types", url: "/settings/documenttypes", iconName: "document-text-outline" }],
  ["13", { moduleName: "Reports", url: "/reports", iconName: "documents-outline" }],
  ["14", { moduleName: "Status List", url: "/statuslist", iconName: "list-outline" }],
  ["15", { moduleName: "Airworthiness Directives", url: "/airdirectives", iconName: "airplane-outline" }],
  ["16", { moduleName: "Assets", url: "/assets", iconName: "build-outline" }],
  ["17", { moduleName: "Clients", url: "/clients", iconName: "business-outline" }],
  ["18", { moduleName: "Roles", url: "/settings/roles", iconName: "key-outline" }],
  ["19", { moduleName: "Logs", url: "/logs", iconName: "book-outline" }]
]);
